.congratulation-page__container {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .heading-red__bold-secondary{
    margin-bottom: 30px;
    text-align: center;
  }
  .heading-secondary{
    margin-top: 40px;
    text-align: center;
  }
}
